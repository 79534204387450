import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { API_URL } from "../../../Config";
import "../Home_IT/Home.css";

const Footer = () => {
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailSuccess, setEmailSuccess] = useState("");

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });
  const [contactError, setContactError] = useState("");
  const [contactSuccess, setContactSuccess] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    if (validateEmail(email)) {
      setEmailError("");
      setEmailSuccess("You have subscribed successfully!");
      console.log("Email submitted:", email);
      setEmail("");
      setTimeout(() => {
        setEmailSuccess("");
      }, 20000);
    } else {
      setEmailError("Please enter a valid email address.");
      setEmailSuccess("");
      setEmail("");
      setTimeout(() => {
        setEmailError("");
      }, 8000);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const cleanedValue = value.replace(/\D/g, "");
      const limitedValue = cleanedValue.slice(0, 10);

      setFormData((prevState) => ({
        ...prevState,
        [name]: limitedValue,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const validateEmailInForm = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  const validateContactForm = () => {
    let formErrors = {};

    if (!formData.firstName.trim()) {
      formErrors.firstName = "First name is required.";
    }
    if (!formData.lastName.trim()) {
      formErrors.lastName = "Last name is required.";
    }
    if (!validateEmailInForm(formData.email)) {
      formErrors.email = "Please enter a valid email address.";
    }
    if (!validatePhoneNumber(formData.phone)) {
      formErrors.phone = "Please enter a valid phone number (10 digits).";
    }
    if (
      Object.keys(formErrors).length === 0 &&
      (!formData.firstName.trim() ||
        !formData.lastName.trim() ||
        !formData.email ||
        !formData.phone ||
        !formData.message.trim())
    ) {
      return { general: "Please fill out all fields." };
    }

    return formErrors;
  };

  const handleContactSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateContactForm();

    if (formErrors.general) {
      setContactError(formErrors.general);
      setContactSuccess("");
      console.log("Form submission error:", formErrors.general);
    } else if (Object.keys(formErrors).length > 0) {
      setContactError(Object.values(formErrors).join(" "));
      setContactSuccess("");
      console.log("Form submission errors:", formErrors);
    } else {
      // Send form data to the backend
      fetch(API_URL + "/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message) {
            setContactSuccess("Your response has been sent successfully!");
            console.log("Email sent successfully:", data.message);
            openPopup_thankYou();
          } else {
            setContactError("Failed to send email");
          }
        })
        .catch((error) => {
          console.error("Error sending email:", error);
          setContactError("Failed to send email");
        });

      // Clear the form
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
      });

      // Reset error/success messages after a timeout
      setTimeout(() => {
        setContactError("");
        setContactSuccess("");
      }, 8000);
    }
  };

  const [isPopup_thankYou, setPopup_thankYou] = useState(false);

  const openPopup_thankYou = () => {
    setPopup_thankYou(true);
  };

  const closePopup_thankYou = () => {
    setPopup_thankYou(false);
  };

  return (
    <div>
      {location.pathname === "/" || location.pathname === "/Home" ? (
        <div>
          {/* sign up for weekly reads starts from here */}
          <div className="bg-[#EAEAEA] dark:bg-[#505050] mx-[3%] lg:mx-[6.8%] rounded-xl py-[3%] mt-[8%] lg:mt-[3%]">
            <div className="mx-[6%]">
              <div>
                <p className="text-2xl md:text-3xl font-bold 2xl:text-5xl">
                  Sign Up For Weekly Reads!
                </p>
                <div className="w-10 h-[2px] bg-teal-600 rounded-md 2xl:w-16 2xl:h-[3px] 2xl:mt-1"></div>
              </div>

              <form
                className="flex flex-col items-center justify-center mt-8"
                required
              >
                <div className="flex flex-row">
                  <input
                    className="text-black w-[60vw] md:w-[50vw] lg:w-[40vw] p-1 md:p-2 rounded-md outline-none"
                    type="text"
                    placeholder="Enter your email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                  />
                  <button
                    className="bg-[#008080] py-1 md:py-1.5 px-3 rounded-md ml-3 text-white hover:scale-90"
                    onClick={handleEmailSubmit}
                    type="submit"
                  >
                    Sign&nbsp;Up
                  </button>
                </div>
                {emailError && (
                  <p className="text-red-500 mt-2">{emailError}</p>
                )}
                {emailSuccess && (
                  <p className="text-green-500 mt-2">{emailSuccess}</p>
                )}
                <span id="ContactUs"></span>

                <p className="text-[12px] leading-5 mt-3 text-center w-72 md:w-full">
                  By clicking Sign Up you're confirming that you agree with
                  our&nbsp;
                  <a
                    href="/TermsConditions"
                    className="underline hover:text-[#008080]"
                  >
                    Terms and Conditions.
                  </a>
                </p>
              </form>
            </div>
          </div>
          {/* sign up for weekly reads ends here */}

          {/* contact us starts from here */}
          <div className="grid grid-cols-2 mt-[8%] lg:mt-[3%]">
            <div
              className="bg-[#008080] w-[30vw] md:w-60 lg:w-[28vw]"
              style={{ borderRadius: "0vw 30px 0vw 0vw" }}
            >
              <p className="mt-48 2xl:mt-80 text-white text-[28px] md:text-4xl lg:text-3xl 2xl:text-[45px] p-5 md:p-8 lg:p-5 font-bold text-center leading-10">
                Get in touch with us!
              </p>
            </div>

            <div className="lg:hidden w-[66vw] -ml-[16vw]">
              <form
                className="p-5 flex flex-col"
                onSubmit={handleContactSubmit}
                required
              >
                <div className="my-4 md:my-6">
                  <p className="text-sm font-semibold text-gray-500">
                    First Name
                  </p>
                  <input
                    className="p-2 w-[53vw] outline-none bg-transparent"
                    type="text"
                    name="firstName"
                    placeholder="Enter first name"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    required
                  />
                  <div className="h-[2px] w-[53vw] bg-[#008080]"></div>
                </div>

                <div className="my-4 md:my-6">
                  <p className="text-sm font-semibold text-gray-500">
                    Last Name
                  </p>
                  <input
                    className="p-2 w-[53vw] outline-none bg-transparent"
                    type="text"
                    name="lastName"
                    placeholder="Enter last name"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    required
                  />
                  <div className="h-[2px] w-[53vw] bg-[#008080]"></div>
                </div>

                <div className="my-4 md:my-6">
                  <p className="text-sm font-semibold text-gray-500">Email</p>
                  <input
                    className="p-2 w-[53vw] outline-none bg-transparent"
                    type="text"
                    name="email"
                    placeholder="Enter email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                  <div className="h-[2px] w-[53vw] bg-[#008080]"></div>
                </div>

                <div className="my-4 md:my-6">
                  <p className="text-sm font-semibold text-gray-500">
                    Phone No.
                  </p>
                  <input
                    className="p-2 w-[53vw] outline-none bg-transparent"
                    type="text"
                    name="phone"
                    placeholder="Enter phone no."
                    pattern="\d{10}"
                    value={formData.phone}
                    onChange={handleInputChange}
                    required
                  />
                  <div className="h-[2px] w-[53vw] bg-[#008080]"></div>
                </div>

                <div className="my-4 md:my-6">
                  <p className="text-sm font-semibold text-gray-500">Message</p>
                  <textarea
                    className="p-2 w-[53vw] outline-none bg-transparent"
                    type="text"
                    name="message"
                    placeholder="Enter message"
                    value={formData.message}
                    onChange={handleInputChange}
                  />
                  <div className="h-[2px] w-[53vw] bg-[#008080]"></div>
                </div>

                <div className="flex justify-end mr-3 md:mr-16">
                  <button
                    className="btn"
                    type="submit"
                    onClick={handleContactSubmit}
                  >
                    <span className="transition_btn"></span>
                    <span className="gradient_btn"></span>
                    <span className="label_btn">Send a message</span>
                  </button>
                </div>

                {contactError && (
                  <p className="text-red-500 mt-2 tex-center">{contactError}</p>
                )}
                {contactSuccess && (
                  <p className="text-green-500 mt-2 text-center">
                    {contactSuccess}
                  </p>
                )}

                {/* <button className='bg-[#008080] hover:bg-white text-white hover:text-teal-600 p-1 md:p-2 my-6 w-36 md:w-48 rounded-md shadow-custom hover:border-2 hover:border-teal-600 '>Send a message</button> */}
              </form>
            </div>

            {/* For big screen */}
            <form
              className="hidden lg:grid grid-cols-2 w-[60vw] -ml-[18vw]"
              onSubmit={handleContactSubmit}
              required
            >
              <div className="p-5">
                <div className="my-16 2xl:my-28">
                  <p className="text-sm 2xl:text-lg font-semibold text-gray-500">
                    First Name
                  </p>
                  <input
                    className="mt-2 w-[24vw] p-2 outline-none bg-transparent"
                    type="text"
                    name="firstName"
                    placeholder="Enter first name"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    tabIndex="1"
                    required
                  />
                  <div className="mt-1 h-[2px] w-[24vw] bg-[#008080]"></div>
                </div>

                <div className="my-16 2xl:my-28">
                  <p className="text-sm 2xl:text-lg font-semibold text-gray-500">
                    Email
                  </p>
                  <input
                    className="mt-2 w-[24vw] p-2 outline-none bg-transparent"
                    type="text"
                    name="email"
                    placeholder="Enter email"
                    value={formData.email}
                    onChange={handleInputChange}
                    tabIndex="3"
                    required
                  />
                  <div className="mt-1 h-[2px] w-[24vw] bg-[#008080]"></div>
                </div>

                <div className="my-16 2xl:my-28">
                  <p className="text-sm 2xl:text-lg font-semibold text-gray-500">
                    Message
                  </p>
                  <input
                    className="mt-2 w-[53vw] p-2 outline-none bg-transparent"
                    type="text"
                    name="message"
                    placeholder="Enter message"
                    value={formData.message}
                    onChange={handleInputChange}
                    tabIndex="5"
                  />
                  <div className="mt-1 h-[2px] w-[55vw] 2xl:w-[54.2vw] bg-[#008080]"></div>
                </div>
              </div>

              <div className="p-5">
                <div className="my-16 2xl:my-28">
                  <p className="text-sm 2xl:text-lg font-semibold text-gray-500">
                    Last Name
                  </p>
                  <input
                    className="mt-2 w-[24vw] p-2 outline-none bg-transparent"
                    type="text"
                    name="lastName"
                    placeholder="Enter last name"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    tabIndex="2"
                    required
                  />
                  <div className="mt-1 h-[2px] w-[24vw] bg-[#008080]"></div>
                </div>

                <div className="my-16 2xl:my-28">
                  <p className="text-sm 2xl:text-lg font-semibold text-gray-500">
                    Phone No.
                  </p>
                  <input
                    className="mt-2 w-[24vw] p-2 outline-none bg-transparent"
                    type="text"
                    name="phone"
                    placeholder="Enter phone no."
                    pattern="\d{10}"
                    value={formData.phone}
                    onChange={handleInputChange}
                    tabIndex="4"
                    required
                  />
                  <div className="mt-1 h-[2px] w-[24vw] bg-[#008080]"></div>
                </div>

                <div className="flex justify-end mt-48 2xl:mt-72 mr-7 2xl:mr-24">
                  <button className="btn">
                    <span className="transition_btn"></span>
                    <span className="gradient_btn"></span>
                    <span className="label_btn">Send a message</span>
                  </button>
                </div>
                {contactError && (
                  <p className="text-red-500 mt-2 tex-center">{contactError}</p>
                )}
                {contactSuccess && (
                  <p className="text-green-500 mt-2 text-center">
                    {contactSuccess}
                  </p>
                )}
              </div>
            </form>

            {isPopup_thankYou && (
              <>
                <div
                  className="overlay_Careers"
                  onClick={closePopup_thankYou}
                ></div>
                <div className="popup_form_Careers">
                  <span className="close_Careers" onClick={closePopup_thankYou}>
                    &times;
                  </span>
                  <form>
                    <div className="text-black">
                      <p className="text-lg md:text-2xl lg:text-lg 2xl:text-3xl font-semibold">
                        Thank you for your response!
                      </p>
                      <p className="mt-[2%]">
                        Your response has been successfully sent.
                      </p>

                      <div className="flex justify-end mt-[3%]">
                        <button
                          className="btn_careers"
                          onClick={closePopup_thankYou}
                        >
                          <span className="transition_btn_careers"></span>
                          <span className="gradient_btn_careers"></span>
                          <span className="label_btn_careers">Done</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
          {/* contact us ends here */}

          <div className="md:hidden bg-black dark:bg-[#1e1e1e] text-white p-3">
            <div className="grid grid-cols-1">
              <div className="grid grid-cols-2 my-2">
                <div>
                  <p className="text-[22px] md:text-3xl font-semibold">
                    Need help with <br />
                    anything?{" "}
                  </p>

                  <div className="flex">
                    <a
                      href="https://twitter.com/_inittechnology"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="h-6 md:h-9 my-4 mr-2 hover:scale-110"
                        src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/e1c32449-5503-49c1-b39b-cd282cf3a200/public"
                        alt="twitter-icon"
                      ></img>{" "}
                      {/* init_twitter.webp */}
                    </a>
                    <a
                      href="https://www.linkedin.com/company/init-technologyco"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="h-6 md:h-9 my-4 mr-2 hover:scale-110"
                        src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/fc62e6a6-b283-45cc-b52a-f6bc8ee11700/public"
                        alt="linkedin-icon"
                      ></img>{" "}
                      {/* init_linkedin.webp */}
                    </a>
                    <a
                      href="https://www.instagram.com/init.technology"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="h-6 md:h-9 my-4 mr-2 hover:scale-110"
                        src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/0eb223c6-bf82-4a6a-a72e-21c1bc99cd00/public"
                        alt="Instagram-icon"
                      ></img>{" "}
                      {/* init_Instagram.webp */}
                    </a>
                    <a
                      href="https://wa.me/+919664570369?text=Hi%20Init%20Technology,%20I`m%20on%20the%20lookout%20for%20innovative%20solutions,%20and%20your%20services%20caught%20my%20eye.%20Can%20we%20chat%20about%20it%20?"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="h-6 md:h-9 my-4 mr-2 hover:scale-110"
                        src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/14514166-3078-46ec-e286-902552a38100/public"
                        alt="Whatsapp-icon"
                      ></img>{" "}
                      {/* init_Whatsapp.webp */}
                    </a>
                    <a
                      href="https://www.facebook.com/profile.php?id=61554743413903&mibextid=9R9pXO"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="h-6 md:h-9 my-4 mr-2 hover:scale-110"
                        src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/5392963c-1b73-493a-49d1-d45661037d00/public"
                        alt="facebook-icon"
                      ></img>{" "}
                      {/* init_facebook.webp */}
                    </a>
                  </div>
                </div>

                <div>
                  <a
                    href="mailto:contact@inittechnology.co"
                    className="text-[12px] md:text-[16px] 2xl:text-2xl font-medium hover:underline"
                    style={{ letterSpacing: "0.2px" }}
                  >
                    contact@inittechnology.co
                  </a>
                  <br />
                  <a
                    href="tel:+919664570369"
                    className="text-[12px] md:text-[16px] 2xl:text-2xl my-1 font-light hover:underline"
                    style={{ letterSpacing: "0.5px" }}
                  >
                    +91-96645 70369
                  </a>
                </div>
              </div>

              <div>
                <div className="grid grid-cols-4 leading-5">
                  <div className="flex flex-col h-32 mx-3">
                    <p className="text-[12px] md:t xt-[16px] my-1 font-bold">
                      Home
                    </p>
                    <a
                      href="#ourServices"
                      className="text-[12px] md:text-[16px] my-1 text-gray-400 font-semibold"
                    >
                      Services
                    </a>
                    <a
                      href="#OurProducts"
                      className="text-[12px] md:text-[16px] my-1 text-gray-400 font-semibold"
                    >
                      Products
                    </a>
                    <a
                      href="#HowitWorks"
                      className="text-[12px] md:text-[16px] my-1 text-gray-400 font-semibold"
                    >
                      How it works
                    </a>
                  </div>

                  <div className="flex flex-col h-32 mx-4">
                    <p className="text-[12px] my-1 font-bold">Legal</p>
                    <a
                      href="/TermsConditions"
                      className="text-[12px] my-1 text-gray-400 font-semibold"
                    >
                      Terms & Conditions
                    </a>
                    <a
                      href="/PrivacyPolicy"
                      className="text-[12px] my-1 text-gray-400 font-semibold"
                    >
                      Privacy & Policy
                    </a>
                  </div>

                  <div className="flex flex-col h-32 mx-3">
                    <p className="text-[12px] my-1 font-bold">Company</p>
                    <a
                      href="#AboutUs"
                      className="text-[12px] my-1 text-gray-400 font-semibold"
                    >
                      About
                    </a>
                    <a
                      href="#Blogs"
                      className="text-[12px] my-1 text-gray-400 font-semibold"
                    >
                      Blogs
                    </a>
                    <a
                      href="/Careers"
                      className="text-[12px] my-1 text-gray-400 font-semibold"
                    >
                      Careers
                    </a>
                  </div>

                  <div className="flex flex-col h-32 mx-3">
                    <p className="text-[12px] my-1 font-bold">Help</p>
                    <a
                      href="#ContactUs"
                      className="text-[12px] my-1 text-gray-400 font-semibold"
                    >
                      Contact
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:hidden bg-[#008080] h-5 w-full flex items-center justify-center text-white font-light text-sm">
            Copyright © Init Technology 2025
          </div>

          {/* For big screens */}
          <div className="hidden md:block bg-black dark:bg-[#1e1e1e] text-white p-5 lg:p-12 lg:px-28 2xl:p-16 2xl:px-56">
            <div className="grid grid-cols-2">
              <div className="w-96 mt-2">
                <div>
                  <p className="text-3xl lg:text-4xl 2xl:text-5xl 2xl:leading-20 font-medium">
                    Need help with <br />
                    anything?{" "}
                  </p>
                </div>

                <div className="flex my-3 md:my-5 2xl:my-12">
                  <a
                    href="https://twitter.com/_inittechnology"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="h-6 md:h-9 2xl:h-14 my-4 mr-4 2xl:mr-6 rounded-md hover:scale-110"
                      src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/e1c32449-5503-49c1-b39b-cd282cf3a200/public"
                      alt="twitter-icon"
                    ></img>{" "}
                    {/* init_twitter.webp */}
                  </a>
                  <a
                    href="https://www.linkedin.com/company/init-technologyco"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="h-6 md:h-9 2xl:h-14 my-4 mr-4 2xl:mr-6 rounded-md hover:scale-110"
                      src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/fc62e6a6-b283-45cc-b52a-f6bc8ee11700/public"
                      alt="linkedin-icon"
                    ></img>{" "}
                    {/* init_linkedin.webp */}
                  </a>
                  <a
                    href="https://www.instagram.com/init.technology"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="h-6 md:h-9 2xl:h-14 my-4 mr-4 2xl:mr-6 rounded-md hover:scale-110"
                      src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/0eb223c6-bf82-4a6a-a72e-21c1bc99cd00/public"
                      alt="Instagram-icon"
                    ></img>{" "}
                    {/* init_Instagram.webp */}
                  </a>
                  <a
                    href="https://wa.me/+919664570369?text=Hi%20Init%20Technology,%20I`m%20on%20the%20lookout%20for%20innovative%20solutions,%20and%20your%20services%20caught%20my%20eye.%20Can%20we%20chat%20about%20it%20?"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="h-6 md:h-9 2xl:h-14 my-4 mr-4 2xl:mr-6 rounded-md hover:scale-110"
                      src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/14514166-3078-46ec-e286-902552a38100/public"
                      alt="Whatsapp-icon"
                    ></img>{" "}
                    {/* init_Whatsapp.webp */}
                  </a>
                  <a
                    href="https://www.facebook.com/profile.php?id=61554743413903&mibextid=9R9pXO"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="h-6 md:h-9 2xl:h-14 my-4 mr-4 2xl:mr-6 rounded-md hover:scale-110"
                      src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/5392963c-1b73-493a-49d1-d45661037d00/public"
                      alt="facebook-icon"
                    ></img>{" "}
                    {/* init_facebook.webp */}
                  </a>
                </div>

                <div>
                  <a
                    href="mailto:contact@inittechnology.co"
                    className="text-[12px] md:text-[16px] 2xl:text-2xl font-medium hover:underline"
                    style={{ letterSpacing: "0.2px" }}
                  >
                    contact@inittechnology.co
                  </a>
                  <br />
                  <a
                    href="tel:+919664570369"
                    className="text-[12px] md:text-[16px] 2xl:text-2xl my-1 font-light hover:underline"
                    style={{ letterSpacing: "0.5px" }}
                  >
                    +91-96645 70369
                  </a>
                </div>
              </div>

              <div className="w-[50vw] -ml-12 lg:-ml-24">
                <div className="grid grid-cols-4">
                  <div className="flex flex-col md:mx-4">
                    <p className="text-sm lg:text-lg 2xl:text-2xl my-2 2xl:my-4 font-semibold">
                      Home
                    </p>
                    <a
                      href="#OurServices"
                      className="text-sm lg:text-lg 2xl:text-2xl my-2 2xl:my-4 text-gray-400 hover:text-teal-600"
                    >
                      Services
                    </a>
                    <a
                      href="#OurProducts"
                      className="text-sm lg:text-lg 2xl:text-2xl my-2 2xl:my-4 text-gray-400 hover:text-teal-600"
                    >
                      Products
                    </a>
                    <a
                      href="#HowItWorks"
                      className="text-sm lg:text-lg 2xl:text-2xl my-2 2xl:my-4 text-gray-400 hover:text-teal-600"
                    >
                      How it works
                    </a>
                  </div>

                  <div className="flex flex-col md:mx-4">
                    <p className="text-sm lg:text-lg 2xl:text-2xl my-2 2xl:my-4 font-semibold">
                      Company
                    </p>
                    <a
                      href="#AboutUs"
                      className="text-sm lg:text-lg 2xl:text-2xl my-2 2xl:my-4 text-gray-400 hover:text-teal-600"
                    >
                      About
                    </a>
                    <a
                      href="#Blogs"
                      className="text-sm lg:text-lg 2xl:text-2xl my-2 2xl:my-4 text-gray-400 hover:text-teal-600"
                    >
                      Blogs
                    </a>
                    <a
                      href="/Careers"
                      className="text-sm lg:text-lg 2xl:text-2xl my-2 2xl:my-4 text-gray-400 hover:text-teal-600"
                    >
                      Careers
                    </a>
                  </div>

                  <div className="flex flex-col md:mx-4">
                    <p className="text-sm lg:text-lg 2xl:text-2xl my-2 2xl:my-4 font-semibold">
                      Legal
                    </p>
                    <a
                      href="/TermsConditions"
                      className="text-sm lg:text-lg 2xl:text-2xl my-2 2xl:my-4 text-gray-400 hover:text-teal-600"
                    >
                      Terms & Conditions
                    </a>
                    <a
                      href="/PrivacyPolicy"
                      className="text-sm lg:text-lg 2xl:text-2xl my-2 2xl:my-4 text-gray-400 hover:text-teal-600"
                    >
                      Privacy & Policy
                    </a>
                  </div>

                  <div className="flex flex-col md:mx-4 lg:ml-12">
                    <p className="text-sm lg:text-lg 2xl:text-2xl my-2 2xl:my-4 font-semibold">
                      Help
                    </p>
                    <a
                      href="#ContactUs"
                      className="text-sm lg:text-lg 2xl:text-2xl my-2 2xl:my-4 text-gray-400 hover:text-teal-600"
                    >
                      Contact
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden md:block bg-[#008080] h-5 2xl:h-7 w-full flex-row items-center text-center text-white font-light text-sm 2xl:text-lg">
            Copyright © Init Technology 2025
          </div>
        </div>
      ) : (
        <div>
          {/* sign up for weekly reads starts from here */}
          <div className="bg-[#EAEAEA] dark:bg-[#505050] mx-[3%] lg:mx-[6.8%] rounded-xl py-[3%] mt-[8%] lg:mt-[3%]">
            <div className="mx-4 md:mx-10 lg:mx-20 2xl:mx-24">
              <div>
                <p className="text-2xl md:text-3xl font-bold 2xl:text-5xl">
                  Sign Up For Weekly Reads!
                </p>
                <div className="w-10 h-[2px] bg-teal-600 rounded-md 2xl:w-16 2xl:h-[3px] 2xl:mt-1"></div>
              </div>

              <form
                className="flex flex-col items-center justify-center mt-8"
                required
              >
                <div className="flex flex-row">
                  <input
                    className="text-black w-[60vw] md:w-[50vw] lg:w-[40vw] p-1 md:p-2 rounded-md outline-none"
                    type="text"
                    placeholder="Enter your email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                  />
                  <button
                    className="bg-[#008080] py-1 md:py-1.5 px-3 rounded-md ml-3 text-white hover:scale-90"
                    onClick={handleEmailSubmit}
                    type="submit"
                  >
                    Sign&nbsp;Up
                  </button>
                </div>
                {emailError && (
                  <p className="text-red-500 mt-2">{emailError}</p>
                )}
                {emailSuccess && (
                  <p className="text-green-500 mt-2">{emailSuccess}</p>
                )}
                <span id="ContactUs"></span>

                <p className="text-[12px] leading-5 mt-3 text-center w-72 md:w-full">
                  By clicking Sign Up you're confirming that you agree with
                  our&nbsp;
                  <a
                    href="/TermsConditions"
                    className="underline hover:text-[#008080]"
                  >
                    Terms and Conditions.
                  </a>
                </p>
              </form>
            </div>
          </div>
          {/* sign up for weekly reads ends here */}

          {/* contact us starts from here */}
          <div className="grid grid-cols-2 mt-14">
            <div
              className="bg-[#008080] w-[30vw] md:w-60 lg:w-[28vw]"
              style={{ borderRadius: "0vw 30px 0vw 0vw" }}
            >
              <p className="mt-48 2xl:mt-80 text-white text-[28px] md:text-4xl lg:text-3xl 2xl:text-[45px] p-5 md:p-8 lg:p-5 font-bold text-center leading-10">
                Get in touch with us!
              </p>
            </div>

            <div className="lg:hidden w-[66vw] -ml-[16vw]">
              <form
                className="p-5 flex flex-col"
                onSubmit={handleContactSubmit}
                required
              >
                <div className="my-4 md:my-6">
                  <p className="text-sm font-semibold text-gray-500">
                    First Name
                  </p>
                  <input
                    className="p-2 w-[53vw] outline-none bg-transparent"
                    type="text"
                    name="firstName"
                    placeholder="Enter first name"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    required
                  />
                  <div className="h-[2px] w-[53vw] bg-[#008080]"></div>
                </div>

                <div className="my-4 md:my-6">
                  <p className="text-sm font-semibold text-gray-500">
                    Last Name
                  </p>
                  <input
                    className="p-2 w-[53vw] outline-none bg-transparent"
                    type="text"
                    name="lastName"
                    placeholder="Enter last name"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    required
                  />
                  <div className="h-[2px] w-[53vw] bg-[#008080]"></div>
                </div>

                <div className="my-4 md:my-6">
                  <p className="text-sm font-semibold text-gray-500">Email</p>
                  <input
                    className="p-2 w-[53vw] outline-none bg-transparent"
                    type="text"
                    name="email"
                    placeholder="Enter email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                  <div className="h-[2px] w-[53vw] bg-[#008080]"></div>
                </div>

                <div className="my-4 md:my-6">
                  <p className="text-sm font-semibold text-gray-500">
                    Phone No.
                  </p>
                  <input
                    className="p-2 w-[53vw] outline-none bg-transparent"
                    type="text"
                    name="phone"
                    placeholder="Enter phone no."
                    pattern="\d{10}"
                    value={formData.phone}
                    onChange={handleInputChange}
                    required
                  />
                  <div className="h-[2px] w-[53vw] bg-[#008080]"></div>
                </div>

                <div className="my-4 md:my-6">
                  <p className="text-sm font-semibold text-gray-500">Message</p>
                  <textarea
                    className="p-2 w-[53vw] outline-none bg-transparent"
                    type="text"
                    name="message"
                    placeholder="Enter message"
                    value={formData.message}
                    onChange={handleInputChange}
                  />
                  <div className="h-[2px] w-[53vw] bg-[#008080]"></div>
                </div>

                <div className="flex justify-end mr-3 md:mr-16">
                  <button
                    className="btn"
                    type="submit"
                    onClick={handleContactSubmit}
                  >
                    <span className="transition_btn"></span>
                    <span className="gradient_btn"></span>
                    <span className="label_btn">Send a message</span>
                  </button>
                </div>

                {contactError && (
                  <p className="text-red-500 mt-2 tex-center">{contactError}</p>
                )}
                {contactSuccess && (
                  <p className="text-green-500 mt-2 text-center">
                    {contactSuccess}
                  </p>
                )}
              </form>
            </div>

            {/* For big screen */}
            <form
              className="hidden lg:grid grid-cols-2 w-[60vw] -ml-[18vw]"
              onSubmit={handleContactSubmit}
              required
            >
              <div className="p-5">
                <div className="my-16 2xl:my-28">
                  <p className="text-sm 2xl:text-lg font-semibold text-gray-500">
                    First Name
                  </p>
                  <input
                    className="mt-2 w-[24vw] p-2 outline-none bg-transparent"
                    type="text"
                    name="firstName"
                    placeholder="Enter first name"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    tabIndex="11"
                    required
                  />
                  <div className="mt-1 h-[2px] w-[24vw] bg-[#008080]"></div>
                </div>

                <div className="my-16 2xl:my-28">
                  <p className="text-sm 2xl:text-lg font-semibold text-gray-500">
                    Email
                  </p>
                  <input
                    className="mt-2 w-[24vw] p-2 outline-none bg-transparent"
                    type="text"
                    name="email"
                    placeholder="Enter email"
                    value={formData.email}
                    onChange={handleInputChange}
                    tabIndex="13"
                    required
                  />
                  <div className="mt-1 h-[2px] w-[24vw] bg-[#008080]"></div>
                </div>

                <div className="my-16 2xl:my-28">
                  <p className="text-sm 2xl:text-lg font-semibold text-gray-500">
                    Message
                  </p>
                  <input
                    className="mt-2 w-[53vw] p-2 outline-none bg-transparent"
                    type="text"
                    name="message"
                    placeholder="Enter message"
                    value={formData.message}
                    onChange={handleInputChange}
                    tabIndex="15"
                  />
                  <div className="mt-1 h-[2px] w-[55vw] 2xl:w-[54.2vw] bg-[#008080]"></div>
                </div>
              </div>

              <div className="p-5">
                <div className="my-16 2xl:my-28">
                  <p className="text-sm 2xl:text-lg font-semibold text-gray-500">
                    Last Name
                  </p>
                  <input
                    className="mt-2 w-[24vw] p-2 outline-none bg-transparent"
                    type="text"
                    name="lastName"
                    placeholder="Enter last name"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    tabIndex="12"
                    required
                  />
                  <div className="mt-1 h-[2px] w-[24vw] bg-[#008080]"></div>
                </div>

                <div className="my-16 2xl:my-28">
                  <p className="text-sm 2xl:text-lg font-semibold text-gray-500">
                    Phone No.
                  </p>
                  <input
                    className="mt-2 w-[24vw] p-2 outline-none bg-transparent"
                    type="text"
                    name="phone"
                    placeholder="Enter phone no."
                    pattern="\d{10}"
                    value={formData.phone}
                    onChange={handleInputChange}
                    tabIndex="14"
                    required
                  />
                  <div className="mt-1 h-[2px] w-[24vw] bg-[#008080]"></div>
                </div>

                <div className="flex justify-end mt-48 2xl:mt-72 mr-7 2xl:mr-24">
                  <button className="btn">
                    <span className="transition_btn"></span>
                    <span className="gradient_btn"></span>
                    <span className="label_btn">Send a message</span>
                  </button>
                </div>
                {contactError && (
                  <p className="text-red-500 mt-2 tex-center">{contactError}</p>
                )}
                {contactSuccess && (
                  <p className="text-green-500 mt-2 text-center">
                    {contactSuccess}
                  </p>
                )}
              </div>
            </form>

            {isPopup_thankYou && (
              <>
                <div
                  className="overlay_Careers"
                  onClick={closePopup_thankYou}
                ></div>
                <div className="popup_form_Careers">
                  <span className="close_Careers" onClick={closePopup_thankYou}>
                    &times;
                  </span>
                  <form>
                    <div className="text-black">
                      <p className="text-lg md:text-2xl lg:text-lg 2xl:text-3xl font-semibold">
                        Thank you for your response!
                      </p>
                      <p className="mt-[2%]">
                        Your response has been successfully sent.
                      </p>

                      <div className="flex justify-end mt-[3%]">
                        <button
                          className="btn_careers"
                          onClick={closePopup_thankYou}
                        >
                          <span className="transition_btn_careers"></span>
                          <span className="gradient_btn_careers"></span>
                          <span className="label_btn_careers">Done</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
          {/* contact us ends here */}

          <div className="md:hidden bg-black dark:bg-[#1e1e1e] text-white p-3">
            <div className="grid grid-cols-1">
              <div className="grid grid-cols-2 my-2">
                <div>
                  <p className="text-[22px] md:text-3xl font-semibold">
                    Need help with <br />
                    anything?{" "}
                  </p>

                  <div className="flex">
                    <a
                      href="https://twitter.com/_inittechnology"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="h-6 md:h-9 my-4 mr-2 hover:scale-110"
                        src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/e1c32449-5503-49c1-b39b-cd282cf3a200/public"
                        alt="twitter-icon"
                      ></img>{" "}
                      {/* init_twitter.webp */}
                    </a>
                    <a
                      href="https://www.linkedin.com/company/init-technologyco"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="h-6 md:h-9 my-4 mr-2 hover:scale-110"
                        src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/fc62e6a6-b283-45cc-b52a-f6bc8ee11700/public"
                        alt="linkedin-icon"
                      ></img>{" "}
                      {/* init_linkedin.webp */}
                    </a>
                    <a
                      href="https://www.instagram.com/init.technology"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="h-6 md:h-9 my-4 mr-2 hover:scale-110"
                        src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/0eb223c6-bf82-4a6a-a72e-21c1bc99cd00/public"
                        alt="Instagram-icon"
                      ></img>{" "}
                      {/* init_Instagram.webp */}
                    </a>
                    <a
                      href="https://wa.me/+919664570369?text=Hi%20Init%20Technology,%20I`m%20on%20the%20lookout%20for%20innovative%20solutions,%20and%20your%20services%20caught%20my%20eye.%20Can%20we%20chat%20about%20it%20?"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="h-6 md:h-9 my-4 mr-2 hover:scale-110"
                        src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/14514166-3078-46ec-e286-902552a38100/public"
                        alt="Whatsapp-icon"
                      ></img>{" "}
                      {/* init_Whatsapp.webp */}
                    </a>
                    <a
                      href="https://www.facebook.com/profile.php?id=61554743413903&mibextid=9R9pXO"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="h-6 md:h-9 my-4 mr-2 hover:scale-110"
                        src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/5392963c-1b73-493a-49d1-d45661037d00/public"
                        alt="facebook-icon"
                      ></img>{" "}
                      {/* init_facebook.webp */}
                    </a>
                  </div>
                </div>

                <div>
                  <a
                    href="mailto:contact@inittechnology.co"
                    className="text-[12px] md:text-[16px] 2xl:text-2xl font-medium hover:underline"
                    style={{ letterSpacing: "0.2px" }}
                  >
                    contact@inittechnology.co
                  </a>
                  <br />
                  <a
                    href="tel:+919664570369"
                    className="text-[12px] md:text-[16px] 2xl:text-2xl my-1 font-light hover:underline"
                    style={{ letterSpacing: "0.5px" }}
                  >
                    +91-96645 70369
                  </a>
                </div>
              </div>

              <div>
                <div className="grid grid-cols-4 leading-5">
                  <div className="flex flex-col h-32 mx-3">
                    <p className="text-[12px] md:t xt-[16px] my-1 font-bold">
                      Home
                    </p>
                    <a
                      href="#ourServices"
                      className="text-[12px] md:text-[16px] my-1 text-gray-400 font-semibold"
                    >
                      Services
                    </a>
                    <a
                      href="#OurProducts"
                      className="text-[12px] md:text-[16px] my-1 text-gray-400 font-semibold"
                    >
                      Products
                    </a>
                    <a
                      href="#HowitWorks"
                      className="text-[12px] md:text-[16px] my-1 text-gray-400 font-semibold"
                    >
                      How it works
                    </a>
                  </div>

                  <div className="flex flex-col h-32 mx-4">
                    <p className="text-[12px] my-1 font-bold">Legal</p>
                    <a
                      href="/TermsConditions"
                      className="text-[12px] my-1 text-gray-400 font-semibold"
                    >
                      Terms & Conditions
                    </a>
                    <a
                      href="/PrivacyPolicy"
                      className="text-[12px] my-1 text-gray-400 font-semibold"
                    >
                      Privacy & Policy
                    </a>
                  </div>

                  <div className="flex flex-col h-32 mx-3">
                    <p className="text-[12px] my-1 font-bold">Company</p>
                    <a
                      href="/OurTeam"
                      className="text-[12px] my-1 text-gray-400 font-semibold"
                    >
                      About
                    </a>
                    <a
                      href="/Blogs"
                      className="text-[12px] my-1 text-gray-400 font-semibold"
                    >
                      Blogs
                    </a>
                    <a
                      href="/Careers"
                      className="text-[12px] my-1 text-gray-400 font-semibold"
                    >
                      Careers
                    </a>
                  </div>

                  <div className="flex flex-col h-32 mx-3">
                    <p className="text-[12px] my-1 font-bold">Help</p>
                    <a
                      href="#ContactUs"
                      className="text-[12px] my-1 text-gray-400 font-semibold"
                    >
                      Contact
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:hidden bg-[#008080] h-5 w-full flex items-center justify-center text-white font-light text-sm">
            Copyright © Init Technology 2025
          </div>

          {/* For big screens */}
          <div className="hidden md:block bg-black dark:bg-[#1e1e1e] text-white p-5 lg:p-12 lg:px-28 2xl:p-16 2xl:px-56">
            <div className="grid grid-cols-2">
              <div className="w-96 mt-2">
                <div>
                  <p className="text-3xl lg:text-4xl 2xl:text-5xl 2xl:leading-20 font-medium">
                    Need help with <br />
                    anything?{" "}
                  </p>
                </div>

                <div className="flex my-3 md:my-5 2xl:my-12">
                  <a
                    href="https://twitter.com/_inittechnology"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="h-6 md:h-9 2xl:h-14 my-4 mr-4 2xl:mr-6 rounded-md hover:scale-110"
                      src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/e1c32449-5503-49c1-b39b-cd282cf3a200/public"
                      alt="twitter-icon"
                    ></img>{" "}
                    {/* init_twitter.webp */}
                  </a>
                  <a
                    href="https://www.linkedin.com/company/init-technologyco"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="h-6 md:h-9 2xl:h-14 my-4 mr-4 2xl:mr-6 rounded-md hover:scale-110"
                      src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/fc62e6a6-b283-45cc-b52a-f6bc8ee11700/public"
                      alt="linkedin-icon"
                    ></img>{" "}
                    {/* init_linkedin.webp */}
                  </a>
                  <a
                    href="https://www.instagram.com/init.technology"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="h-6 md:h-9 2xl:h-14 my-4 mr-4 2xl:mr-6 rounded-md hover:scale-110"
                      src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/0eb223c6-bf82-4a6a-a72e-21c1bc99cd00/public"
                      alt="Instagram-icon"
                    ></img>{" "}
                    {/* init_Instagram.webp */}
                  </a>
                  <a
                    href="https://wa.me/+919664570369?text=Hi%20Init%20Technology,%20I`m%20on%20the%20lookout%20for%20innovative%20solutions,%20and%20your%20services%20caught%20my%20eye.%20Can%20we%20chat%20about%20it%20?"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="h-6 md:h-9 2xl:h-14 my-4 mr-4 2xl:mr-6 rounded-md hover:scale-110"
                      src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/14514166-3078-46ec-e286-902552a38100/public"
                      alt="Whatsapp-icon"
                    ></img>{" "}
                    {/* init_Whatsapp.webp */}
                  </a>
                  <a
                    href="https://www.facebook.com/profile.php?id=61554743413903&mibextid=9R9pXO"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="h-6 md:h-9 2xl:h-14 my-4 mr-4 2xl:mr-6 rounded-md hover:scale-110"
                      src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/5392963c-1b73-493a-49d1-d45661037d00/public"
                      alt="facebook-icon"
                    ></img>{" "}
                    {/* init_facebook.webp */}
                  </a>
                </div>

                <div>
                  <a
                    href="mailto:contact@inittechnology.co"
                    className="text-[12px] md:text-[16px] 2xl:text-2xl font-medium hover:underline"
                    style={{ letterSpacing: "0.2px" }}
                  >
                    contact@inittechnology.co
                  </a>
                  <br />
                  <a
                    href="tel:+919664570369"
                    className="text-[12px] md:text-[16px] 2xl:text-2xl my-1 font-light hover:underline"
                    style={{ letterSpacing: "0.5px" }}
                  >
                    +91-96645 70369
                  </a>
                </div>
              </div>

              <div className="w-[50vw] -ml-12 lg:-ml-24">
                <div className="grid grid-cols-4">
                  <div className="flex flex-col md:mx-4">
                    <p className="text-sm lg:text-lg 2xl:text-2xl my-2 2xl:my-4 font-semibold">
                      Home
                    </p>
                    <a
                      href="/"
                      className="text-sm lg:text-lg 2xl:text-2xl my-2 2xl:my-4 text-gray-400 hover:text-teal-600"
                    >
                      Services
                    </a>
                    <a
                      href="/"
                      className="text-sm lg:text-lg 2xl:text-2xl my-2 2xl:my-4 text-gray-400 hover:text-teal-600"
                    >
                      Products
                    </a>
                    <a
                      href="/"
                      className="text-sm lg:text-lg 2xl:text-2xl my-2 2xl:my-4 text-gray-400 hover:text-teal-600"
                    >
                      How it works
                    </a>
                  </div>

                  <div className="flex flex-col md:mx-4">
                    <p className="text-sm lg:text-lg 2xl:text-2xl my-2 2xl:my-4 font-semibold">
                      Company
                    </p>
                    <a
                      href="/OurTeam"
                      className="text-sm lg:text-lg 2xl:text-2xl my-2 2xl:my-4 text-gray-400 hover:text-teal-600"
                    >
                      About
                    </a>
                    <a
                      href="/Blogs"
                      className="text-sm lg:text-lg 2xl:text-2xl my-2 2xl:my-4 text-gray-400 hover:text-teal-600"
                    >
                      Blogs
                    </a>
                    <a
                      href="/Careers"
                      className="text-sm lg:text-lg 2xl:text-2xl my-2 2xl:my-4 text-gray-400 hover:text-teal-600"
                    >
                      Careers
                    </a>
                  </div>

                  <div className="flex flex-col md:mx-4">
                    <p className="text-sm lg:text-lg 2xl:text-2xl my-2 2xl:my-4 font-semibold">
                      Legal
                    </p>
                    <a
                      href="/TermsConditions"
                      className="text-sm lg:text-lg 2xl:text-2xl my-2 2xl:my-4 text-gray-400 hover:text-teal-600"
                    >
                      Terms & Conditions
                    </a>
                    <a
                      href="/PrivacyPolicy"
                      className="text-sm lg:text-lg 2xl:text-2xl my-2 2xl:my-4 text-gray-400 hover:text-teal-600"
                    >
                      Privacy & Policy
                    </a>
                  </div>

                  <div className="flex flex-col md:mx-4 lg:ml-12">
                    <p className="text-sm lg:text-lg 2xl:text-2xl my-2 2xl:my-4 font-semibold">
                      Help
                    </p>
                    <a
                      href="#ContactUs"
                      className="text-sm lg:text-lg 2xl:text-2xl my-2 2xl:my-4 text-gray-400 hover:text-teal-600"
                    >
                      Contact
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden md:block bg-[#008080] h-5 2xl:h-7 w-full flex-row items-center text-center text-white font-light text-sm 2xl:text-lg">
            Copyright © Init Technology 2025
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;
