import React from "react";
import HeaderIT from "../Header_IT/Header";
import FooterIT from "../Footer_IT/Footer";
import { motion as m } from "framer-motion";

const Blog1 = () => {
  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: [0, 0.5, 1] }}
      transition={{ duration: 0.9, ease: "easeIn" }}
      style={{ willChange: "opacity" }}
    >
      <div className="bg-white dark:bg-black dark:text-white text-[16px] leading-7 md:text-xl md:leading-8 lg:text-[16px] lg:leading-7 2xl:text-2xl 2xl:leading-10">
        <HeaderIT />
        <br />
        <div className="px-2 md:px-5 lg:mt-20 2xl:mt-28">
          <div className="bg-[#EAEAEA] dark:bg-[#505050] h-32 md:h-40 lg:h-48 2xl:h-72 lg:mx-16 2xl:mx-48 rounded-xl py-4 md:py-8 lg:py-10 2xl:py-16 mt-14 flex items-center justify-center">
            <img
              className="h-32 md:h-48 lg:h-52 2xl:h-80 mt-12 md:mt-24 lg:mt-24 2xl:mt-40 mx-2 2xl:mx-4"
              src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/14e8c95d-1613-476a-ca2b-4d1b1caa7a00/public"
              alt="Tech-trends"
            />
            {/* init_tech_trends2.webp */}
            <div className="flex flex-col mx-2">
              <p className="my-1 lg:my-2 2xl:my-4 text-xl md:text-3xl lg:text-4xl 2xl:text-5xl font-light">
                Tech Trends 2024:
              </p>
              <p className="my-1 lg:my-2 2xl:my-4 text-2xl md:text-4xl lg:text-5xl 2xl:text-6xl font-medium">
                What's Hot in IT
              </p>
            </div>
          </div>

          <div className="mt-10 md:mt-20 2xl:mt-32 mx-4 md:mx-10 lg:mx-36 2xl:mx-72">
            <div>
              <p>
                Dive into the future with INIT Technology! As we step into 2024,
                let’s explore the cutting-edge tech trends shaping our digital
                landscape.
              </p>
              <p className="mt-3 lg:mt-5 2xl:mt-12">Here’s a sneak peek: </p>
            </div>

            <div className="my-5 2xl:my-10 bg-teal-400 h-[1px] mx-5"></div>

            <div className="mt-3 lg:mt-5 2xl:mt-12">
              <p className="my-1 lg:my-2 2xl:my-3">1. AI-Powered Innovations</p>
              <p className="my-1 lg:my-2 2xl:my-3">2. Virtual Frontiers</p>
              <p className="my-1 lg:my-2 2xl:my-3">
                3. ⁠Sustainability in Tech
              </p>
              <p className="my-1 lg:my-2 2xl:my-3">4. ⁠Next-Gen Interfaces</p>
            </div>

            <div className="mt-3 lg:mt-5 2xl:mt-12">
              <p className="my-6 2xl:my-8">
                <b>1. AI-Powered Innovations:</b> Witness the transformative
                power of smart automation and personalized experiences,
                redefining the very fabric of industries.
              </p>

              <p className="my-6 2xl:my-8">
                <b>2. Virtual Frontiers:</b> Immerse yourself in the boundless
                possibilities of the Metaverse, where connectivity and
                experiences are being reinvented in ways unimaginable.
              </p>

              <p className="my-6 2xl:my-8">
                <b>3. Sustainability in Tech:</b> Join us in curating
                eco-friendly solutions, as we strive to create a world where
                technology and sustainability coexist.
              </p>

              <p className="my-6 2xl:my-8">
                <b>4. Next-Gen Interfaces:</b> Explore the future of user
                interfaces and experiences, where innovation seamlessly merges
                with intuitive interaction, opening up new dimensions of user
                experience.
              </p>
            </div>

            <div className="mt-8 lg:mt-14 2xl:mt-24">
              <p className="font-medium text-sm 2xl:text-xl">Naju.K</p>
              <p className="text-sm mt-2 2xl:text-lg">
                September 1, 2024 ∙ 3 mins read
              </p>
            </div>
          </div>
        </div>
        <FooterIT />
      </div>
    </m.div>
  );
};

export default Blog1;
