import React, { useEffect, useRef, useState } from "react";
import "./Home.css";
import HeaderIT from "../Header_IT/Header";
import FooterIT from "../Footer_IT/Footer";

import { ImQuotesLeft } from "react-icons/im";
import { ImQuotesRight } from "react-icons/im";
import { SlArrowRight } from "react-icons/sl";
import { SiTicktick } from "react-icons/si";
import { MdOutlineCircle } from "react-icons/md";
import { API_URL } from "../../../Config";

import {
  FaWordpress,
  FaMobile,
  FaShoppingCart,
  FaCode,
  FaPen,
  FaCogs,
  FaChartLine,
  FaDatabase,
  FaCloud,
  FaLaptopCode,
  FaUserShield,
  FaRobot,
  FaPalette,
  FaUsers,
  FaBrain,
} from "react-icons/fa";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Lottie from "lottie-react";
import animation2 from "../../../animations/animation2.json";
import coding from "../../../animations/Coding.json";
import debug from "../../../animations/Debug.json";
import ideation from "../../../animations/Ideation.json";
import meeting from "../../../animations/Meeting.json";
import scalling from "../../../animations/Scalling.json";
import OurProduct_bg from "../../../animations/OurProduct_bg.json";

import { motion as m } from "framer-motion";

const milestones = [1, 2, 3, 4, 5];

const milestoneLabels = {
  1: "Meeting",
  2: "Ideation",
  3: "Coding",
  4: "Debugging",
  5: "Scaling",
};

const animations = ["meeting", "ideation", "coding", "debug", "scalling"];

const ServiceCard = ({ icon: Icon, title, description, link }) => {
  const disabledServices = [
    "Data Engineering and Analytics",
    "SaaS Development",
    "Cybersecurity Services",
    "IT Staff Augmentation",
    "IoT Solutions",
    "AI-Powered Solutions",
    "Digital Product Design",
    "IT Consulting Services",
  ];

  const isDisabled = disabledServices.includes(title);

  return (
    <div className="group p-4 bg-white dark:bg-[#252525] rounded-xl shadow-md hover:shadow-custom dark:hover:shadow-white transition-all duration-300 transform hover:-translate-y-1 relative">
      <div className="flex flex-col items-center text-center space-y-4">
        <Icon className="w-12 h-12 text-[#008080] group-hover:text-teal-600 transition-colors duration-300" />
        <h3 className=" font-semibold text-[#008080] group-hover:text-teal-600">
          {title}
        </h3>
        <p className="text-gray-600 dark:text-gray-200  text-left">
          {description}
        </p>

        {isDisabled ? (
          <button
            className="mt-3 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            disabled
          >
            Future Projects in Motion
            <div className="h-[2px] w-20 -ml-2.5 bg-gray-400"></div>
          </button>
        ) : (
          <button
            className="mt-3 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            onClick={() => (window.location.href = link)}
          >
            View more
            <div className="h-[2px] w-16 -ml-3 bg-black dark:bg-white transition-all duration-500 ease-in-out group-hover:ml-2 group-hover:bg-teal-500"></div>
          </button>
        )}
      </div>
    </div>
  );
};

const Home = () => {
  const [visibleCards, setVisibleCards] = useState(6);
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize >= 1024) {
      setVisibleCards(6);
    } else if (screenSize >= 768) {
      setVisibleCards(4);
    } else {
      setVisibleCards(2);
    }
  }, [screenSize]);

  const loadMoreCards = () => {
    if (screenSize >= 1024) {
      setVisibleCards(visibleCards + 3);
    } else if (screenSize >= 768) {
      setVisibleCards(visibleCards + 4);
    } else {
      setVisibleCards(visibleCards + 2);
    }
  };

  const services = [
    {
      icon: FaCode,
      title: "Website Designing & Development",
      description:
        "No more looking around- Init Technology brings you an affordable destination for Modern and Chic Web Development Solutions.",
      link: "/OurServices_IT/WebsiteDevelopment",
    },
    {
      icon: FaShoppingCart,
      title: "E-Commerce Development",
      description:
        "From user-friendly interfaces to payment integration and mobile responsiveness, we'll ensure that your e-commerce website is optimised for success.",
      link: "/OurServices_IT/Ecommerce",
    },
    {
      icon: FaLaptopCode,
      title: "Software Development",
      description:
        "Whether you need a custom web application, mobile app, or enterprise software solution, we have the expertise to bring your vision to life.",
      link: "/OurServices_IT/SoftwareDevelopment",
    },
    {
      icon: FaMobile,
      title: "Mobile App Development",
      description:
        "We leverage the latest technologies and best practices to ensure that your app is not only functional but also scalable, secure, and user-friendly.",
      link: "/OurServices_IT/MobileAppDevelopment",
    },
    {
      icon: FaPen,
      title: "Written Production",
      description:
        "We understand the power of words, from writing captivating content for your website, engaging articles for your blog, or polished copy for your marketing materials, our team of experienced writers.",
      link: "/OurServices_IT/WrittenProduction",
    },
    {
      icon: FaCogs,
      title: "Management Services",
      description:
        "Explore our management services below to learn how we can support your organisation's growth and development.",
      link: "/OurServices_IT/ManagementServices",
    },
    {
      icon: FaWordpress,
      title: "WordPress",
      description:
        "Work with our experienced designers to create a unique and visually stunning website that reflects your brand identity.",
      link: "/OurServices_IT/Wordpress",
    },
    {
      icon: FaChartLine,
      title: "SEO",
      description:
        "With millions of websites competing for attention online, it's crucial to ensure that your website ranks well in search engine results to attract organic traffic and reach your target audience effectively.",
      link: "/OurServices_IT/Seo",
    },
    {
      icon: FaDatabase,
      title: "Data Engineering and Analytics",
      description:
        "We assist you in understanding your data into valuable insights through data pipelines, predictive analytics, and business intelligence solutions. Together, let's make informed, data-driven decisions!",
    },
    {
      icon: FaCloud,
      title: "SaaS Development",
      description:
        "Have a fantastic concept for a cloud-based SaaS platform? We're ready to create scalable, multi-tenant applications with smooth APIintegrations that evolve alongside your business.",
    },
    {
      icon: FaUserShield,
      title: "Cybersecurity Services",
      description:
        "Ensure your business's safety with our professional cybersecurity services. We offer everything from vulnerability assessments to compliance consulting, helping you safeguard your data and systems against cyber threats.",
    },
    {
      icon: FaUsers,
      title: "IT Staff Augmentation",
      description:
        "Are you looking to expand your team? Our talented IT professionals are prepared to step in and achieve results. Whether you need software developers, designers, or tech consultants, we have the expertise you're seeking.",
    },
    {
      icon: FaRobot,
      title: "IoT Solutions",
      description:
        "Embrace the future with IoT-enabled systems! We focus on integrating smart devices, providing real-time monitoring, and developing IoT applications to enhance your operations and connect your business.",
    },
    {
      icon: FaBrain,
      title: "AI-Powered Solutions",
      description:
        "Enhance your operations with AI tools such as smart chatbots, machine learning systems, and AI-driven suggestions. Together, we can introduce innovation to your business!",
    },
    {
      icon: FaPalette,
      title: "Digital Product Design",
      description:
        "Exceptional UI/UX design is essential for capturing user interest. Init develops digital experiences that resonate with your brand and bring joy to your customers, ranging from interactive prototypes to stunning interfaces.",
    },
    {
      icon: FaLaptopCode,
      title: "IT Consulting Services",
      description:
        "Technology can feel daunting, but our IT consulting specialists simplify the process. We assist you with IT strategy development, optimising your infrastructure, and creating customised solutions that lead to success.",
    },
  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const responsiveBlogs = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 900 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 900, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1.5,
    },
  };

  const [showRow1, setShowRow1] = useState(true);
  const [showRow2, setShowRow2] = useState(false);

  const [activeButton, setActiveButton] = useState("btn10");

  const handleClick_btn10 = () => {
    setShowRow1(true);
    setShowRow2(false);
    setActiveButton("btn10");
  };

  const handleClick_btn11 = () => {
    setShowRow2(true);
    setShowRow1(false);
    setActiveButton("btn11");
  };

  const [currentStep, setCurrentStep] = useState(1);
  const [selectedAnimation, setSelectedAnimation] = useState(animations[0]);
  const [fade, setFade] = useState(false);
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const handleOnClick = (milestone) => {
    setCurrentStep(milestone);
    setSelectedAnimation(animations[milestone - 1]);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isVisible) {
        setFade(true);
        setTimeout(() => {
          setSelectedAnimation((prev) => {
            const currentIndex = animations.indexOf(prev);
            const nextIndex = (currentIndex + 1) % animations.length;
            setCurrentStep(nextIndex + 1);
            return animations[nextIndex];
          });
          setFade(false);
        }, 400); // fade effect
      }
    }, 3000); // every 3 seconds

    return () => clearInterval(interval);
  }, [isVisible]);

  useEffect(() => {
    const currentSection = sectionRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (currentSection) {
      observer.observe(currentSection);
    }

    return () => {
      if (currentSection) {
        observer.unobserve(currentSection);
      }
    };
  }, []);

  const blogPosts = [
    {
      href: "/Blog6",
      imgSrc:
        "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/70ff0ad0-581c-4f7b-e014-c102924ed900/public", //init_blog6_cover.webp
      title: "Is Shopify Still the Top E-Commerce Platform in 2025?",
      author: "Naju.K",
      date: "Feb 26, 2025",
      readTime: "2 min read",
    },
    {
      href: "/Blog5",
      imgSrc:
        "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/1741758f-d019-4722-0d7e-a2a455288400/public", //init_blog5_cover.webp
      title: "Scaling a SaaS startup in 2025: Key Insights for Success.",
      author: "Naju.K",
      date: "Feb 20, 2025",
      readTime: "4 min read",
    },
    {
      href: "/Blog4",
      imgSrc:
        "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/058afac2-3c33-42f7-95e1-d53e8a430100/public", //init_blog4_cover.webp
      title: "Why Businesses Will Benefit from AI-Powered Web Apps in 2025.",
      author: "Naju.K",
      date: "Feb 10, 2025",
      readTime: "3 min read",
    },
    {
      href: "/Blog3",
      imgSrc:
        "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/8a887af9-22ef-4e62-b3ee-94cac66a0800/public", //init_blog3_cover.webp
      title: "Tech Trends 2024: How SaaS is changing the Software Industry",
      author: "Naju.K",
      date: "Sep 14, 2024",
      readTime: "4 min read",
    },
    {
      href: "/Blog2",
      imgSrc:
        "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/aabe2924-278c-43de-80e9-b99fd8cbf900/public", //init_blog2_cover.webp
      title:
        "Tech Trends 2024: Why React is the Best Choice for Modern Web Apps",
      author: "Naju.K",
      date: "Sep 10, 2024",
      readTime: "6 min read",
    },
    {
      href: "/Blog1",
      imgSrc:
        "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/6634e202-d55c-4e46-4634-08ea1e060400/public", //init_tech_trends.webp
      title: "Tech Trends 2024: What’s Hot in IT",
      author: "Naju.K",
      date: "Sep 1, 2024",
      readTime: "3 min read",
    },
  ];

  const partners = [
    {
      name: "Mr. Sikandar Bathvar",
      link: "https://www.linkedin.com/in/sikandar-bathvar-5a2b46154/",
      title: "Director",
    },
    {
      name: "Ms. Naju Keshwala",
      link: "https://www.linkedin.com/in/naju-keshwala-37075912a/",
      title: "Director",
    },
  ];

  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await fetch(API_URL + "/getTestimonials");
        if (!response.ok) {
          throw new Error(
            `Failed to fetch testimonials: ${response.status} ${response.statusText}`
          );
        }
        const data = await response.json();
        setTestimonials(data);
      } catch (error) {
        console.error("Error fetching testimonials:", error);
      }
    };

    fetchTestimonials();
  }, []);

  const quotsync_Features = [
    "AI<br /> INTEGRATED",
    "AUTOMATE QUOTATIONS ON THE GO",
    "EASE OF MANAGEMENT & MORE",
    "GENERATE ACCURATE QUOTES INSTANTLY",
  ];

  const waveriseHub_Features = [
    "TASK, PERFORMANCE MANAGEMENT & MORE",
    "TRACK<br /> LOCATION",
    "REAL-TIME UPDATES AND TRACKING",
    "GENERATE ACCURATE QUOTES INSTANTLY",
  ];

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: [0, 0.5, 1] }}
      transition={{ duration: 0.9, ease: "easeIn" }}
      style={{ willChange: "opacity" }}
    >
      <div className="bg-white dark:bg-black dark:text-white text-[16px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-7 md:leading-8 lg:leading-7 2xl:leading-10">
        {/* <SplashScreen /> */}
        <HeaderIT />

        {/* Hero section starts from here */}
        <div className="">
          <div className="absolute flex -mt-[16%] ml-[66%] md:-mt-[6%] lg:ml-[80%]">
            <Lottie
              animationData={animation2}
              loop={true}
              autoplay={true}
              className="h-[32vw] w-[32vw] lg:h-[20vw] lg:w-[20vw]"
              rendererSettings={{
                preserveAspectRatio: "xMidYMid slice",
              }}
            />
          </div>

          <div className="mt-16 md:mt-20 flex flex-col items-center">
            <div className="text-4xl md:text-5xl lg:text-6xl 2xl:text-8xl font-bold mt-[12%]">
              <span className="text-[#008080]">INIT&nbsp;</span>for the long run
            </div>

            <div className="flex flex-col text-center items-center text-sm md:text-lg lg:text-[16px] 2xl:text-2xl mt-[8%]">
              See what happens when technology
              <br className="lg:hidden" /> meets your Vision
              <a href="#ourServices" className="discoverMore_btn">
                Discover more
              </a>
            </div>

            <div
              className="text-center text-[#B2BEB5] text-[10px] md:text-[14px] 2xl:text-xl leading-[2] mt-[8%] lg:mt-[4%] w-[90vw] lg:w-[75vw]"
              style={{ wordSpacing: "12px", letterSpacing: "0.5px" }}
            >
              <span id="ourServices"></span>
              <span id="OurServices"></span>
              HTML CSS PHP JAVA DATABASE MySQL PostgreSQL FIGMA SHOPIFY Node.JS
              WORDPRESS Angular ReactJS React-Native FRAMER TypeScript
              Spring-Boot
            </div>

            <div className="absolute -ml-[88%] lg:-ml-[85%] mt-[12%] lg:mt-[0%] opacity-50">
              <Lottie
                animationData={animation2}
                loop={true}
                autoplay={true}
                className="h-[70vw] w-[70vw] lg:h-[40vw] lg:w-[40vw]"
                rendererSettings={{
                  preserveAspectRatio: "xMidYMid slice",
                }}
              />
            </div>
          </div>
        </div>
        {/* Hero section ends here */}

        {/* Our services starts from here */}
        <div>
          <div className="min-h-screen mt-[8%] lg:my-[3%] 2xl:my-[5%] mx-[3%] lg:mx-[12%]">
            <div className="max-w-7xl mx-auto">
              <div className="mb-16">
                <div>
                  <p className="text-3xl font-bold 2xl:text-5xl">
                    Our Services
                  </p>
                  <div className="w-10 h-[2px] bg-teal-600 rounded-md 2xl:w-16 2xl:h-[3px] 2xl:mt-1"></div>
                </div>

                <p className="mt-3">
                  Welcome to Init Technology.
                  <br />
                  As a top-rated business IT consulting company, we specialise
                  in curating innovative services for your needs. Whether you're
                  a startup or an established enterprise seeking operational
                  optimisation, our dedicated team is here to support you at
                  every stage.
                </p>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                {services.slice(0, visibleCards).map((service, index) => (
                  <ServiceCard
                    key={index}
                    icon={service.icon}
                    title={service.title}
                    description={service.description}
                    link={service.link}
                  />
                ))}
              </div>

              {visibleCards < services.length && (
                <div className="text-center mt-10">
                  <button
                    className="px-4 py-1 bg-teal-600 text-white rounded-md hover:bg-teal-500 transition-all duration-300"
                    onClick={loadMoreCards}
                  >
                    Load More
                  </button>
                </div>
              )}

              <span id="HowItWorks"></span>
              <span id="HowitWorks"></span>
            </div>
          </div>
        </div>

        {/* Our services ends here */}

        {/* How it works starts from here */}
        <br />
        <div className="mt-[0%] mx-[2%] lg:mx-[8%]" ref={sectionRef}>
          <div className="border border-black dark:border-white rounded-xl py-[1%] flex flex-col items-center">
            <div className="w-full flex flex-col items-center">
              <div
                className={`flex items-center transition-opacity duration-500 ${
                  fade ? "opacity-0" : "opacity-100"
                }`}
              >
                {selectedAnimation === "meeting" && (
                  <Lottie
                    animationData={meeting}
                    loop={true}
                    autoplay={true}
                    className="h-[60vw] w-[60vw] lg:h-[28vw] lg:w-[28vw]"
                    rendererSettings={{
                      preserveAspectRatio: "xMidYMid slice",
                    }}
                  />
                )}
                {selectedAnimation === "ideation" && (
                  <Lottie
                    animationData={ideation}
                    loop={true}
                    autoplay={true}
                    className="h-[60vw] w-[60vw] lg:h-[28vw] lg:w-[28vw]"
                    rendererSettings={{
                      preserveAspectRatio: "xMidYMid slice",
                    }}
                  />
                )}
                {selectedAnimation === "coding" && (
                  <Lottie
                    animationData={coding}
                    loop={true}
                    autoplay={true}
                    className="h-[60vw] w-[60vw] lg:h-[28vw] lg:w-[28vw]"
                    rendererSettings={{
                      preserveAspectRatio: "xMidYMid slice",
                    }}
                  />
                )}
                {selectedAnimation === "debug" && (
                  <Lottie
                    animationData={debug}
                    loop={true}
                    autoplay={true}
                    className="h-[60vw] w-[60vw] lg:h-[28vw] lg:w-[28vw]"
                    rendererSettings={{
                      preserveAspectRatio: "xMidYMid slice",
                    }}
                  />
                )}
                {selectedAnimation === "scalling" && (
                  <Lottie
                    animationData={scalling}
                    loop={true}
                    autoplay={true}
                    className="h-[60vw] w-[60vw] lg:h-[28vw] lg:w-[28vw]"
                    rendererSettings={{
                      preserveAspectRatio: "xMidYMid slice",
                    }}
                  />
                )}
              </div>

              <div className="progress-container">
                <div className="progress-bar">
                  {milestones.map((milestone, index) => (
                    <div
                      key={index}
                      className={`milestone ${
                        currentStep >= milestone ? "completed" : ""
                      }`}
                      onClick={() => handleOnClick(milestone)}
                    >
                      <span
                        className={`milestone-icon ${
                          currentStep === milestone ? "current" : ""
                        }`}
                      >
                        {currentStep >= milestone ? (
                          <SiTicktick />
                        ) : (
                          <MdOutlineCircle />
                        )}
                      </span>
                      <div className="milestone-text text-[3vw] lg:text-[1vw]">
                        {milestoneLabels[milestone] || ""}
                      </div>
                    </div>
                  ))}
                </div>
                <span id="OurProducts"></span>
              </div>
            </div>
          </div>
        </div>
        {/* How it works ends here */}

        {/* Our products starts from here */}
        <div className="mt-[8%] lg:mt-[5%] 2xl:mt-20 mx-[3%] lg:mx-[12%]">
          <div>
            <p className="text-2xl md:text-3xl font-bold 2xl:text-5xl">
              Our Products
            </p>
            <div className="w-10 h-[2px] bg-teal-600 rounded-md 2xl:w-16 2xl:h-[3px] 2xl:mt-1"></div>
          </div>

          <div className="flex justify-center mt-5 text-lg md:text-2xl lg:text-xl 2xl:text-3xl">
            <button
              className={`quotaSync_Home ${
                activeButton === "btn10" ? "active_quotaSync_Home" : ""
              }`}
              onClick={handleClick_btn10}
            >
              &nbsp;QuotSync&nbsp;
            </button>

            <button
              className={`waveriseHub_Home ${
                activeButton === "btn11" ? "active_waveriseHub_Home" : ""
              }`}
              onClick={handleClick_btn11}
            >
              &nbsp;Waverise Hub&nbsp;
            </button>
          </div>

          {showRow1 && (
            <div className="flex flex-col items-center text-center">
              <p className="mt-8 lg:w-[45vw] 2xl:my-10">
                QuotSync- your essential invoice management software.
              </p>
              <p className="mt-3 underline underline-offset-8 font-semibold">
                Coming Soon
              </p>

              <div className="grid grid-cols-2 md:flex gap-5 mt-5 items-center">
                {quotsync_Features.map((feature, index) => (
                  <div
                    key={index}
                    className="relative p-1 rounded h-40 w-40 2xl:h-60 2xl:w-60 !border border-black dark:border-white"
                  >
                    <Lottie
                      animationData={OurProduct_bg}
                      loop={true}
                      autoplay={true}
                      className="opacity-90 md:opacity-80 absolute bottom-0 right-0 md:-bottom-4 md:-right-2 z-0 h-[12vw] w-[12vw] md:h-[5vw] md:w-[5vw]"
                      rendererSettings={{
                        preserveAspectRatio: "xMidYMid slice",
                      }}
                    />
                    <div className="relative z-10 flex items-center justify-center h-full">
                      <span
                        className="font-bold text-black dark:text-white"
                        dangerouslySetInnerHTML={{ __html: feature }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {showRow2 && (
            <div className="flex flex-col items-center text-center">
              <p className="mt-8 lg:w-[45vw] 2xl:my-10">
                WaveRise Hub empowers you to track your team's performance,
                ensure accountability, and optimise productivity—all without the
                need for micromanagement.
              </p>
              <p className="mt-3 underline underline-offset-8 font-semibold">
                Coming Soon
              </p>

              <div className="grid grid-cols-2 md:flex gap-5 mt-5 items-center">
                {waveriseHub_Features.map((feature, index) => (
                  <div
                    key={index}
                    className="relative p-1 rounded h-40 w-40 2xl:h-60 2xl:w-60 !border border-black dark:border-white"
                  >
                    <Lottie
                      animationData={OurProduct_bg}
                      loop={true}
                      autoplay={true}
                      className="opacity-90 md:opacity-80 absolute bottom-0 right-0 md:-bottom-4 md:-right-2 z-0 h-[12vw] w-[12vw] md:h-[5vw] md:w-[5vw]"
                      rendererSettings={{
                        preserveAspectRatio: "xMidYMid slice",
                      }}
                    />
                    <div className="relative z-10 flex items-center justify-center h-full">
                      <span
                        className="font-bold text-black dark:text-white"
                        dangerouslySetInnerHTML={{ __html: feature }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        {/* Our products ends here */}

        {/* Testimonials starts from here */}
        <div className="bg-[#EAEAEA] dark:bg-[#505050] mx-[3%] lg:mx-[6.8%] rounded-xl py-[2%] mt-[8%] lg:mt-[5%]">
          <div className="mx-[6%]">
            <div>
              <p className="text-2xl md:text-3xl font-bold 2xl:text-5xl">
                Testimonials
              </p>
              <div className="w-10 h-[2px] bg-teal-600 rounded-md 2xl:w-16 2xl:h-[3px] 2xl:mt-1"></div>
            </div>

            <div>
              <Carousel
                responsive={responsive}
                // showDots={true}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={9000}
                transitionDuration={1000}
                keyBoardControl={true}
                // removeArrowOnDeviceType={["tablet", "mobile"]}
                arrows={true}
              >
                {testimonials.map((testimonial, index) => (
                  <div
                    key={index}
                    className="flex-col items-center w-[80vw] md:w-[70vw] lg:w-[50vw] mx-auto mt-3 mb-8 cursor-pointer py-2 px-7"
                  >
                    <ImQuotesLeft />
                    <p className="mx-6">
                      {testimonial.reviews}
                      {testimonial.client_name && (
                        <>
                          <br /> <br />
                          <span>
                            <b>
                              <a
                                href={testimonial.website_link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                - {testimonial.client_name}
                              </a>
                            </b>
                          </span>
                        </>
                      )}
                    </p>
                    <ImQuotesRight className="float-right" />
                    <span id="AboutUs"></span>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
        {/* Testimonials ends here */}

        {/* About us starts from here */}
        <div className="mt-[8%] lg:mt-[5%] mx-[3%] lg:mx-[12%]">
          <div className="grid lg:grid-cols-2">
            <div className="">
              <p className="text-2xl md:text-3xl font-bold 2xl:text-5xl">
                About Us
              </p>
              <div className="w-10 h-[2px] bg-teal-600 rounded-md 2xl:w-16 2xl:h-[3px] 2xl:mt-1"></div>

              <p className="mt-5">
                The foundation of Init Technology stemmed from the idea that
                while there are many varieties to choose from in the IT
                industry, only a few stand out and provide quality assurance,
                deliver timely, and communicate consistently throughout a
                project and thereafter!
                <br />
                <br />
                We are committed to building long-term partnerships with our
                clients, providing ongoing support and guidance to help them
                succeed. Our team is intricately chosen based on their
                strengths, enabling us to fulfil these responsibilities with
                excellence.
              </p>
            </div>

            <div className="flex flex-col mt-[8%] lg:mt-0 lg:ml-auto">
              <p className="text-2xl md:text-3xl lg:text-2xl font-bold 2xl:text-4xl">
                Our&nbsp;Team
              </p>
              <div className="w-10 h-[2px] bg-teal-600 rounded-md 2xl:w-16 2xl:h-[3px] 2xl:mt-1"></div>

              <div className="mt-5 2xl:text-2xl">
                {partners.map((partner, index) => (
                  <div key={index} className="my-3">
                    <p className="flex">
                      {partner.name}
                      <a href={partner.link} target="_blank" rel="noreferrer">
                        <img
                          className="h-5 ml-2 hover:scale-90"
                          src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/e548aea1-298b-440e-7f6d-f5630aac4c00/public"
                          alt="LinkedIn"
                        />
                        {/* init_linkedin_icon.webp */}
                      </a>
                    </p>
                    <p>
                      <i className="font-light">{partner.title}</i>
                    </p>
                  </div>
                ))}

                <span id="Blogs"></span>

                <div className="mt-[2%] lg:ml-[10vw] group cursor-pointer">
                  <a href="/OurTeam" className="flex">
                    View&nbsp;more
                  </a>
                  <div className="h-[2px] w-16 -ml-3 bg-black dark:bg-white transition-all duration-500 ease-in-out group-hover:ml-2 group-hover:bg-[#008080]"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* About us ends here */}

        {/* Blogs starts from here */}
        <div className="mt-[8%] lg:mt-[4%] mx-[3%] lg:mx-[12%]">
          <div className="grid grid-cols-2">
            <div className="w-[64vw] md:w-[72vw]">
              <Carousel
                responsive={responsiveBlogs}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={5000}
                transitionDuration={2000}
                keyBoardControl={true}
                // removeArrowOnDeviceType={["tablet", "mobile"]}
                arrows={false}
              >
                {blogPosts.map((blog, index) => (
                  <a key={index} href={blog.href}>
                    <div className="bg-white dark:bg-[#252525] shadow-md hover:shadow-custom m-[3%] lg:mx-[6%] p-[3%] rounded-lg">
                      <img src={blog.imgSrc} alt="Blog-Img" />
                      <p className="font-medium mt-2">{blog.title}</p>
                      <p className="text-sm 2xl:text-xl mt-6">{blog.author}</p>
                      <p className="text-[12px] 2xl:text-lg font-light mt-1">{`${blog.date} ∙ ${blog.readTime}`}</p>
                    </div>
                  </a>
                ))}
              </Carousel>
            </div>

            <div className="flex items-center w-[12vw] md:w-[10vw] ml-[27vw] md:ml-[32vw]">
              <a href="/Blogs">
                <SlArrowRight className="bg-[#008080] hover:border-teal-600 hover:border-2 hover:bg-transparent text-white hover:text-teal-600 rounded-full h-11 w-11 p-2 md:p-3 ml-2 md:ml-5 lg:ml-12 md:h-14 md:w-14 2xl:h-16 2xl:w-16" />
              </a>
            </div>
          </div>
        </div>
        {/* Blogs ends here */}

        <FooterIT />
      </div>
    </m.div>
  );
};

export default Home;
