import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SplashScreenIT from "./components/Init_Technology/SplashScreen_IT/SplashScreenIT";
import Header from "./components/Init_Technology/Header_IT/Header";
import Footer from "./components/Init_Technology/Footer_IT/Footer";
import Home from "./components/Init_Technology/Home_IT/Home";
import Blogs from "./components/Init_Technology/Blogs_IT/Blogs";
import Blog1 from "./components/Init_Technology/Blogs_IT/Blog1";
import OurTeam from "./components/Init_Technology/OurTeam_IT/OurTeam";
import Careers from "./components/Init_Technology/Careers_IT/Careers";
import TermsConditions from "./components/Init_Technology/TermsConditions_IT/TermsConditions";
import WebsiteDevelopment from "./components/Init_Technology/OurServices_IT/WebsiteDevelopment";
import Ecommerce from "./components/Init_Technology/OurServices_IT/Ecommerce";
import SoftwareDevelopment from "./components/Init_Technology/OurServices_IT/SoftwareDevelopment";
import MobileAppDevelopment from "./components/Init_Technology/OurServices_IT/MobileAppDevelopment";
import WrittenProduction from "./components/Init_Technology/OurServices_IT/WrittenProduction";
import ManagementServices from "./components/Init_Technology/OurServices_IT/ManagementServices";
import Wordpress from "./components/Init_Technology/OurServices_IT/Wordpress";
import Seo from "./components/Init_Technology/OurServices_IT/Seo";
import CaseStudy from "./components/Init_Technology/CaseStudy_IT/CaseStudy";
// import OurServices from './components/Init_Technology/OurServices_IT/OurServices';
import PrivacyPolicy from "./components/Init_Technology/PrivacyPolicy_IT/PrivacyPolicy";
import Blog2 from "./components/Init_Technology/Blogs_IT/Blog2";
import Blog3 from "./components/Init_Technology/Blogs_IT/Blog3";
import Blog4 from "./components/Init_Technology/Blogs_IT/Blog4";
import Blog5 from "./components/Init_Technology/Blogs_IT/Blog5";
import Blog6 from "./components/Init_Technology/Blogs_IT/Blog6";

import HomeWH from "./components/WaveriseHub/Home_WH/HomeWH";
import HeaderWH from "./components/WaveriseHub/Header_WH/HeaderWH";
import FooterWH from "./components/WaveriseHub/Footer_WH/FooterWH";
import SplashScreenWH from "./components/WaveriseHub/SplashScreen_WH/SplashScreenWH";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/SplashScreenIT" element={<SplashScreenIT />} />
          <Route path="/Header" element={<Header />} />
          <Route path="/Footer" element={<Footer />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Blogs" element={<Blogs />} />
          <Route path="/Blog1" element={<Blog1 />} />
          <Route path="/OurTeam" element={<OurTeam />} />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/TermsConditions" element={<TermsConditions />} />
          <Route
            path="/OurServices_IT/WebsiteDevelopment"
            element={<WebsiteDevelopment />}
          />
          <Route path="/OurServices_IT/Ecommerce" element={<Ecommerce />} />
          <Route
            path="/OurServices_IT/SoftwareDevelopment"
            element={<SoftwareDevelopment />}
          />
          <Route
            path="/OurServices_IT/MobileAppDevelopment"
            element={<MobileAppDevelopment />}
          />
          <Route
            path="/OurServices_IT/WrittenProduction"
            element={<WrittenProduction />}
          />
          <Route
            path="/OurServices_IT/ManagementServices"
            element={<ManagementServices />}
          />
          <Route path="/OurServices_IT/Wordpress" element={<Wordpress />} />
          <Route path="/OurServices_IT/Seo" element={<Seo />} />
          <Route path="/CaseStudy/:projectName" element={<CaseStudy />} />
          {/* <Route path='/OurServices_IT/OurServices' element={< OurServices/>} /> */}
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/Blog2" element={<Blog2 />} />
          <Route path="/Blog3" element={<Blog3 />} />
          <Route path="/Blog4" element={<Blog4 />} />
          <Route path="/Blog5" element={<Blog5 />} />
          <Route path="/Blog6" element={<Blog6 />} />

          <Route path="/HomeWH" element={<HomeWH />} />
          <Route path="/HeaderWH" element={<HeaderWH />} />
          <Route path="/FooterWH" element={<FooterWH />} />
          <Route path="/SplashScreenWH" element={<SplashScreenWH />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
