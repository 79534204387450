import React from "react";
import HeaderIT from "../Header_IT/Header";
import FooterIT from "../Footer_IT/Footer";
import { motion as m } from "framer-motion";

const Blogs = () => {
  const blogs = [
    {
      href: "/Blog6",
      imgSrc:
        "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/70ff0ad0-581c-4f7b-e014-c102924ed900/public", //init_blog6_cover.webp
      title: "Is Shopify Still the Top E-Commerce Platform in 2025?",
      author: "Naju.K",
      date: "Feb 26, 2025",
      readTime: "2 min read",
    },
    {
      href: "/Blog5",
      imgSrc:
        "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/1741758f-d019-4722-0d7e-a2a455288400/public", //init_blog5_cover.webp
      title: "Scaling a SaaS startup in 2025: Key Insights for Success.",
      author: "Naju.K",
      date: "Feb 20, 2025",
      readTime: "4 min read",
    },
    {
      href: "/Blog4",
      imgSrc:
        "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/058afac2-3c33-42f7-95e1-d53e8a430100/public", //init_blog4_cover.webp
      title: "Why Businesses Will Benefit from AI-Powered Web Apps in 2025.",
      author: "Naju.K",
      date: "Feb 10, 2025",
      readTime: "3 min read",
    },
    {
      href: "/Blog3",
      imgSrc:
        "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/8a887af9-22ef-4e62-b3ee-94cac66a0800/public", //init_blog3_cover.webp
      title: "Tech Trends 2024: How SaaS is changing the Software Industry",
      author: "Naju.K",
      date: "Sep 14, 2024",
      readTime: "4 min read",
    },
    {
      href: "/Blog2",
      imgSrc:
        "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/aabe2924-278c-43de-80e9-b99fd8cbf900/public", //init_blog2_cover.webp
      title:
        "Tech Trends 2024: Why React is the Best Choice for Modern Web Apps",
      author: "Naju.K",
      date: "Sep 10, 2024",
      readTime: "6 min read",
    },
    {
      href: "/Blog1",
      imgSrc:
        "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/6634e202-d55c-4e46-4634-08ea1e060400/public", //init_tech_trends.webp
      title: "Tech Trends 2024: What’s Hot in IT",
      author: "Naju.K",
      date: "Sep 1, 2024",
      readTime: "3 min read",
    },
  ];

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: [0, 0.5, 1] }}
      transition={{ duration: 0.9, ease: "easeIn" }}
      style={{ willChange: "opacity" }}
    >
      <div className="bg-white dark:bg-black dark:text-white text-[16px] leading-7 md:text-xl md:leading-8 lg:text-[16px] lg:leading-7 2xl:text-2xl 2xl:leading-10">
        <HeaderIT />
        <br />
        <div className="px-2 md:px-5 lg:mt-20 2xl:mt-28">
          <div className="flex flex-col items-center justify-center my-12 md:my-14">
            <div className="h-32 md:h-40 lg:h-48 2xl:h-72 w-full lg:w-[88vw] lg:mx-16 2xl:mx-48 z-0 rounded-xl overflow-hidden">
              <img
                className="h-full w-full"
                src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/0a699c4d-3e81-4e13-970c-920a7cea9b00/public"
                alt="background"
              />
              {/* init_background.webp */}
            </div>
            <div className="flex items-center justify-center h-32 md:h-40 lg:h-48 2xl:h-72 -mt-32 md:-mt-40 lg:-mt-48 2xl:-mt-72 z-0">
              <p className="text-black text-center text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl font-semibold">
                Blogs
              </p>
            </div>
          </div>

          <div className="mt-10 mx-4 md:mx-10 lg:mx-36 2xl:mx-72">
            <div className="grid grid-cols-1 lg:grid-cols-3 justify-between">
              {blogs.map((blog, index) => (
                <a key={index} href={blog.href}>
                  <div className="bg-white dark:bg-[#252525] p-4 lg:p-5 lg:py-8 2xl:p-8 mx-[5%] m-2 md:m-5 lg:m-6 2xl:m-12 rounded-xl shadow-lg hover:shadow-custom hover:scale-105 transition-transform duration-300 ease-in-out">
                    <div>
                      <img
                        className="w-full my-[5%] lg:my-0"
                        src={blog.imgSrc}
                        alt="Blogs-Img"
                      />
                    </div>
                    <div className="mt-1 lg:mt-2 2xl:mt-4">
                      <p className="font-semibold leading-6">{blog.title}</p>
                      <div className="mt-3 lg:mt-6 2xl:mt-12">
                        <p className="text-sm 2xl:text-xl font-medium">
                          {blog.author}
                        </p>
                        <p className="text-sm 2xl:text-lg lg:mt-1 2xl:mt-2">
                          {blog.date} ∙ {blog.readTime}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
        <FooterIT />
      </div>
    </m.div>
  );
};

export default Blogs;
